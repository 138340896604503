import React from "react";
import Banner from './Banner';

function Main() {
    return (
      <>
        <Banner />
      </>
    );
  }
  
  export default Main;